import Axios from 'axios';
import BossApiParameters from "@/utils/Model/ApiService/BossApiParameters";
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import FormDataService from "@/utils/Services/FormDataService";
import LocalStorageService from "@/utils/Services/LocalStorageService";
import Router from "@/router";

export default class AbstractApiService {

    constructor() {
        this.parameterService = new BossApiParameters();
        this._paginator = null;
        this._staticModel = null;
        this._beforeInsertMethod = null;

        Axios.defaults.baseURL = config.API_PROXY;
        Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        Axios.defaults.headers.get['Content-Type'] = 'application/json';

        if (LocalStorageService.get('token')) {
            Axios.defaults.headers.common = {'Authorization': `Bearer ${LocalStorageService.get('token')}`};
        }
    }

    static convertToFormData(payload) {
        return FormDataService.create(payload);
    }

    /**
     * Run this method before insert in Vuex store
     * @param method
     * @returns {AbstractApiService}
     */
    beforeInsert(method = null) {
        if (typeof method !== 'function') {
            throw 'The value of beforeInsert should be a function';
        }

        this._beforeInsertMethod = method;

        return this;
    }

    /**
     * Add a where condition to the api call
     * @param column
     * @param value
     * @returns {AbstractApiService}
     */
    where(column, value) {
        this.parameterService.addWhere(column, value);

        return this;
    }

    /**
     * Add multiple conditions to the api call
     * @param filters
     * @returns {AbstractApiService}
     */
    filtered(filters = {}) {
        this.parameterService.filtered(filters);

        return this;
    }

    /**
     * Add one or more appends[] to the api call
     * @param appends
     * @returns {AbstractApiService}
     */
    appended(appends = []) {
        this.parameterService.addAppends(appends);

        return this;
    }

    /**
     * Add multiple sortBy[] to the api call
     * @param orders
     * @returns {AbstractApiService}
     */
    ordered(orders = {}) {
        this.parameterService.addOrders(orders);

        return this;
    }

    /**
     * Add a single sortBy[] to the api call
     * @param column
     * @param direction
     * @returns {AbstractApiService}
     */
    orderBy(column, direction = 'asc') {
        this.parameterService.addOrder(column, direction);

        return this;
    }

    /**
     * add multiple with[] to the api url
     * @param withs
     * @returns {AbstractApiService}
     */
    with(withs = []) {
        this.parameterService.addWiths(withs);

        return this;
    }

    /**
     * set paginator as reference and update after api call
     * @param paginator
     * @returns {AbstractApiService}
     */
    paginator(paginator = {}) {
        this._paginator = paginator;
        this.parameterService.setPaginate(true);

        if (paginator.currentPage !== undefined) {
            this.parameterService.setPage(paginator.currentPage);
        }

        if (paginator.perPage !== undefined) {
            this.parameterService.setLimit(paginator.perPage);
        }

        return this;
    }

    /**
     * set the limit of the api call
     * @param limit
     * @returns {AbstractApiService}
     */
    limit(limit) {
        this.parameterService.setLimit(limit);

        return this;
    }

    /**
     * resolve the paginate api response
     * @param response
     * @param useStore
     * @returns {PromiseLike<T>|Promise<T>|*|*[]}
     */
    async returnMultiplePaginated(response, useStore = false) {
        if (response.data === undefined || response.data.data === undefined || !Array.isArray(response.data.data)) {
            if (Object.keys(response.data).length === 0) {
                this._paginator.currentPage = 1;
                this._paginator.lastPage = 1;
                this._paginator.total = 0;
                return [];
            }
            throw Error('paginated response is not an array');
        }

        this._paginator.currentPage = response.data.meta.current_page;
        this._paginator.lastPage = response.data.meta.last_page;
        this._paginator.perPage = parseInt(response.data.meta.per_page);
        this._paginator.total = response.data.meta.total;

        if (this._beforeInsertMethod) {
            this._beforeInsertMethod(response.data);
        }

        if (useStore) {
            await this._staticModel.insert(response.data.data);
            return this._staticModel.query().findIn(map(response.data.data, 'id'));
        }

        return response.data.data;
    }

    /**
     * resolve the api response of multiple item
     * @param response
     * @param useStore
     * @returns {PromiseLike<T>|Promise<T>|*|*[]}
     */
    async returnMultiple(response, useStore = false) {
        if (response.data === undefined || !(response.data instanceof Object)) {
            throw Error('response is not an array or object');
        }

        if (!Array.isArray(response.data)) {
            if (Object.keys(response.data).length > 0) {
                throw Error(this._staticModel.name + ' response is a single item instead of an array, use getSingle()');
            }

            return [];
        }

        if (this._beforeInsertMethod) {
            this._beforeInsertMethod(response.data);
        }

        if (useStore) {
            await this._staticModel.insert({data: response.data});
            return this._staticModel.query().with(this.parameterService.withs).findIn(map(response.data, 'id'));
        }

        return response.data;
    }

    /**
     * resolve the response of a single item
     * @param response
     * @param useStore
     * @returns {PromiseLike<T>|Promise<T>|*}
     */
    async returnSingle(response, useStore = true) {
        if (Array.isArray(response.data) && response.data.length === 0) {
            return null;
        }

        if (response.data === undefined || !(response.data instanceof Object) || Array.isArray(response.data)) {
            throw Error('response is not an object');
        }

        if (this._beforeInsertMethod) {
            this._beforeInsertMethod(response.data);
        }

        if (useStore) {
            await this._staticModel.insert({data: response.data});

            return this._staticModel.query().with(this.parameterService.withs).find(response.data.id);
        }

        return response.data;
    }

    _catch(error) {
        console.error(error);

        if (error.response.status === 401 && Router.currentRoute.path !== '/login') {
            Router.push('/login?logout=1');
        }

        let message = error?.message;
        if (error?.response?.data?.message) {
            if (error.response.data.message) {
                message = error.response.data.message;
            } else {
                message = error.response.data.message;
            }
            if (error.response.data.errors !== undefined && !isEmpty(error.response.data.errors)) {
                message += "<br />";
                Object.keys(error.response.data.errors).forEach(key => {
                    if (error.response.data.errors[key]) {
                        message += error.response.data.errors[key] + "<br />";
                    } else {
                        message += error.response.data.errors[key] + "<br />";
                    }
                })
            }
        }

        throw error;
    }
}

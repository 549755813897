import BossModel from "@/models/BossModel";
import moment from "moment";
import constants from "@/constants";
import Identity from "@/models/Dossier/Passport/Identity/Identity";

export default class Version extends BossModel {

    static apiUrl = 'customer/traveller/version';

    static entity = 'customerTravellerVersions';

    static fields() {
        return {
            id: this.number(null).nullable(),
            customer_traveller_id: this.number(null).nullable(),
            firstname: this.attr(null),
            lastname: this.attr(null),
            date_of_birth: this.attr(null),
            age_group_cid: this.number(null).nullable(),
            gender_cid: this.number(0).nullable(),
            identity: this.hasOne(Identity, 'dossier_traveller_id'),
        }
    }

    Gender() {
        let gender = constants.genders.find(item => item.id === this.gender_cid);
        if (gender === undefined) {
            return 'Unknown';
        }

        return gender.label;
    }

    Pronouns() {
        let pronouns = constants.pronouns.find(item => item.id === this.gender_cid);
        if (pronouns === undefined) {
            return 'Unknown';
        }

        return pronouns.label;
    }

    CurrentAge() {
        return this.AgeOnDate(moment());
    }

    AgeOnDate(date) {
        if (this.date_of_birth) {
            return date.diff(moment(this.date_of_birth), 'years');
        }

        return null;
    }
}

import BossModel from "@/models/BossModel";
import PublicImage from "@/models/PublicImage/PublicImage";

export default class User extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'user';

    /**
     *
     * @type {string}
     */
    static entity = 'users';

    static fields () {
        return {
            id: this.number(null).nullable(),
            name: this.attr(null),
            firstname: this.attr(null),
            lastname: this.attr(null),
            email: this.attr(null),
            telephone: this.attr(null),
            square_public_image_id: this.number(null).nullable(),
            xelion_external: this.attr(null),
            squarePublicImage: this.belongsTo(PublicImage, 'square_public_image_id')
        }
    }
}

import BossModel from "@/models/BossModel";
import Dossier from '@/models/Dossier/Dossier';
import ProposalNonClosingDay from "@/models/Proposal/ProposalNonClosingDay/ProposalNonClosingDay";
import Ticket from "@/models/Proposal/Ticket/Ticket";
import Product from "@/models/Proposal/Product/Product";

export default class Proposal extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'proposal';

    /**
     *
     * @type {string}
     */
    static entity = 'proposals';

    static fields() {
        return {
            id: this.number(null).nullable(),
            dossier_id: this.number(null).nullable(),
            dossier: this.belongsTo(Dossier, 'dossier_id'),
            tickets: this.hasMany(Ticket, 'proposal_id'),
            products: this.hasMany(Product, 'proposal_id'),
            nonClosingDays: this.hasMany(ProposalNonClosingDay, 'proposal_id'),
        }
    }
}

import BossModel from "@/models/BossModel";

export default class Currency extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'currency';

    /**
     *
     * @type {string}
     */
    static entity = 'currencies';

    static fields() {
		return {
			id: this.attr(null),
            code: this.attr(null),
			name: this.attr(null),
		}
	}

}

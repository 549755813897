import BossModel from "@/models/BossModel";

export default class Product extends BossModel {

    static apiUrl = 'proposal/product';

    static entity = 'proposalProducts';

    static fields() {
        return {
            id: this.number(null).nullable(),
            proposal_id: this.number(null).nullable(),
            agent_hash: this.attr(null).nullable(),
        }
    }
}

import BossModel from "@/models/BossModel";
import {mutateDateTimeWithoutSeconds} from "@/utils/mutators";

export default class Flight extends BossModel {

    static apiUrl = 'dossier/ticket/flight';

    static entity = 'dossierTicketFlights';

    static fields() {
        return {
            id: this.number(null).nullable(),
            airline_id: this.number(null).nullable(),
            airline_name: this.attr(null),
            departures_at: this.attr(null),
            arrives_at: this.attr(null),
            departures_from: this.attr(null),
            departs_to: this.attr(null),
            flight_number: this.attr(null),
        }
    }

    static mutators () {
        return {
            departures_at: mutateDateTimeWithoutSeconds,
            arrives_at: mutateDateTimeWithoutSeconds,
        }
    }
}

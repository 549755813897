import BossModel from "@/models/BossModel";
import Detail from "@/models/Agent/Product/Detail/Detail";
import Status from "@/models/Agent/Product/Status/Status";
import AgentCode from "@/models/Agent/Code/Code";
import {sortBy} from "lodash";
import Dossier from "@/models/Dossier/Dossier";
import Price from "@/models/Agent/Product/Price/Price";

export default class Product extends BossModel {

    static apiUrl = 'agent/product';

    static entity = 'agentProducts';

    static fields() {
        return {
            id: this.number(null).nullable(),
            hash: this.attr(null),
            dossier_id: this.number(null).nullable(),
            agent_code_id: this.number(null).nullable(),
            reference: this.attr(null),
            name: this.attr(null),
            product_type_id: this.number(null).nullable(),
            start_date: this.attr(null),
            end_date: this.attr(null),
            notes: this.attr(null),
            local_price: this.number(null).nullable(),
            details: this.hasMany(Detail, 'agent_product_id'),
            status: this.hasOne(Status, 'agent_product_id'),
            statuses: this.hasMany(Status, 'agent_product_id'),
            agentCode: this.belongsTo(AgentCode, 'agent_code_id'),
            dossier: this.belongsTo(Dossier, 'dossier_id'),
            price: this.hasOne(Price, 'agent_product_id'),
            proposalProduct: this.attr(null),
        }
    }

    get lastStatus() {
        const statuses = this.getRelated('statuses');

        if (statuses.length === 0) {
            throw new Error('AgentProduct.lastStatus is not found of product ' + this.id);
        }

        return sortBy(statuses, 'id').pop();
    }

    static accept(productHash, comment) {
        return this.api().action(productHash + '/accept').create({
            'comment': comment,
        });
    }

    static acknowledgeStatus(productHash, comment) {
        return this.api().action(productHash + '/acknowledge').create({
            'comment': comment,
        });
    }

    static priceUpdate(productHash, localPrice, currencyId, comment) {
        return this.api().action(productHash + '/price-update').create({
            'currency_id': currencyId,
            'local_price': localPrice,
            'comment': comment,
        });
    }

    static deny(productHash, comment) {
        return this.api().action(productHash + '/deny').create({
            'comment': comment,
        });
    }
}

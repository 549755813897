import BossModel from "@/models/BossModel";
import Agent from '@/models/Agent/Agent';
import Dossier from "@/models/Dossier/Dossier";
import FileModel from "@/models/File/File";
import Version from "@/models/Customer/Traveller/Version/Version";

export default class Attachment extends BossModel {

    static apiUrl = 'agent/attachment';

    static entity = 'agentAttachment';

    static fields() {
        return {
            id: this.number(null).nullable(),
            dossier_id: this.number(null).nullable(),
            agent_id: this.number(null).nullable(),
            agent: this.belongsTo(Agent, 'agent_id'),
            dossier: this.belongsTo(Dossier, 'dossier_id'),
            latestVersion: this.belongsTo(Version, 'version_id'),
        }
    }
}

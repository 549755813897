import BossModel from "@/models/BossModel";

export default class PublicImage extends BossModel {

    /**
     * @type {string}
     */
    static apiUrl = 'public-image';

    /**
     * @type {string}
     */
    static entity = 'publicImages';

    static fields() {
        return {
            id: this.number(null).nullable(),
            filename: this.attr(null),
            width: this.number(null).nullable(),
            height: this.number(null).nullable(),
            size: this.attr(null),
            compression:  this.number(null).nullable(),
            media_library_item_image_id: this.attr(null),
            alt: this.attr(null),
            sizes: this.attr(null),
            available_images: this.attr(null),
            url: this.attr(null),
        }
    }
}

import objectToFormData from 'object-to-formdata';

/**
 * @class FormDataService
 * @description Service to handle form data
 * @documentation https://github.com/therealparmesh/object-to-formdata#readme
 */
export default class FormDataService {

    static create(objToSendAsPostRequest) {
        return objectToFormData.serialize(objToSendAsPostRequest, {
            allowEmptyArrays: true,
            booleansAsIntegers: true,
            indices: true,
        });
    }
}

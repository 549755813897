export default class LocalStorageService {

    static get(key) {
        return JSON.parse(window.localStorage.getItem(key));
    }

    static has(key) {
        return window.localStorage.getItem(key) !== null;
    }

    static set(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    static remove(key) {
        window.localStorage.removeItem(key);
    }
}

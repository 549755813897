import StaticModelApiService from "@/utils/Model/ApiService/StaticModelApiService";
import { Model } from '@vuex-orm/core'
import InstanceModelApiService from "@/utils/Model/ApiService/InstanceModelApiService";

export default class BossModel extends Model {

    /**
     *
     * @type {null}
     */
    static entity = null;

    /**
     *
     * @type {null}
     */
    static baseEntity = null;

    /**
     *
     * @type {null}
     */
    static apiUrl = null;

    /**
     *
     * @returns {StaticModelApiService}
     */
    static api() {
        return new StaticModelApiService(this);
    }

    /**
     *
     * @returns {InstanceModelApiService}
     */
    api() {
        return new InstanceModelApiService(this);
    }

    /**
     *
     * @param relationName
     * @param filter
     * @returns {*}
     */
    getRelated(relationName, filter) {
        const relations = relationName.split('.');

        let data = this.constructor.query().where(filter).with(relationName).find(this.id);
        relations.some(relationPart => {
            if (!data[relationPart]) {
                data = null;
                return true;
            }
            data = data[relationPart];
        });

        return data;
    }
}




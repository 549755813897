import AbstractApiService from "@/utils/Model/ApiService/AbstractApiService";
import Axios from 'axios';

export default class StaticModelApiService extends AbstractApiService{

    constructor(model) {
        super();

        this._url = model.apiUrl;
        this._staticModel = model;
    }

    /**
     * set a custom url for current api call
     * @param url
     * @returns {AbstractApiService}
     */
    url(url = '') {
        this._url = url;

        return this;
    }

    /**
     * set the url after the base model url
     * @param action
     * @returns {StaticModelApiService}
     */
    action(action) {
        this._url = this._staticModel.apiUrl + '/' + action;

        return this;
    }

    /**
     * get multiple items from the api
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    get(useStore = false) {
        return Axios.get(this._url + this.parameterService.getUrl()).then(response => {
            if (this._paginator !== null) {
                return this.returnMultiplePaginated(response, useStore);
            }
            return this.returnMultiple(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * use api call with custom url that return single item
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async getSingle(useStore = true) {
        return Axios.get(this._url + this.parameterService.getUrl()).then(response => {
            return this.returnSingle(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * get response from as single value
     * @returns {Promise<AxiosResponse<T>>}
     */
    async getValue() {
        return Axios.get(this._url + this.parameterService.getUrl()).then(response => {
            return response.data;
        }).catch(error => {
            return this._catch(error);
        });
    }

    async getBlob() {
        return Axios.get(this._url + this.parameterService.getUrl(), {
            responseType: 'blob',
        }).then(response => {
            return response.data;
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * use index call but return single model
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async first(useStore = true) {
        this.limit(1);
        return this.get(false).then(response => {
            if (response[0] !== undefined) {
                return this.returnSingle({data: response[0]}, useStore);
            }

            return null;
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * get single item
     * @param id
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async find(id, useStore = true) {
        if (id === null || id === undefined) {
            throw Error('id is required in find()');
        }

        return Axios.get(this._url + '/' + id + this.parameterService.getUrl(true)).then(response => {
            return this.returnSingle(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * update in api by current model instance
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async update(payload = [], useStore = true) {
        payload = Object.assign({_method: 'put'}, payload);
        return Axios.post(this._url, this.constructor.convertToFormData(payload)).then(response => {
            return this.returnSingle(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * update in api by current model instance and return multiple records
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async updateMultiple(payload = [], useStore = false) {
        payload = Object.assign({_method: 'put'}, payload);
        return Axios.post(this._url, this.constructor.convertToFormData(payload)).then(response => {
            return this.returnMultiple(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     *
     * @param id
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async updateById(id, payload = [], useStore = true) {
        payload = Object.assign({_method: 'put'}, payload);
        return Axios.post(this._url + '/' + id, this.constructor.convertToFormData(payload)).then(response => {
            return this.returnSingle(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * create item in api by payload
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async create(payload = [], useStore = true) {
        payload = Object.assign({_method: 'post'}, payload);
        return Axios.post(this._url, this.constructor.convertToFormData(payload)).then(response => {
            return this.returnSingle(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * update in api by current model instance and return multiple records
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async createMultiple(payload = [], useStore = false) {
        payload = Object.assign({_method: 'post'}, payload);
        return Axios.post(this._url, this.constructor.convertToFormData(payload)).then(response => {
            return this.returnMultiple(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     *
     * @param payload
     * @returns {Promise<T>}
     */
    async delete(payload = []) {
        return Axios.delete(this._url, {data: payload}).then(response => {
            return response.data;
        }).catch(error => {
            return this._catch(error);
        });
    }
}

import BossModel from "@/models/BossModel";
import constants from "@/constants";
import User from "@/models/User/User";

export default class Status extends BossModel {

    static apiUrl = 'agent/product/status';

    static entity = 'agentProductStatuses';

    static fields() {
        return {
            id: this.number(null).nullable(),
            agent_product_id: this.number(null).nullable(),
            status_cid: this.number(null).nullable(),
            agent_id: this.number(null).nullable(),
            user_id: this.number(null).nullable(),
            created_at: this.attr(null),
            comments: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
        }
    }

    StatusProps() {
        return Object.values(constants.agentStatuses).find(item => item.id === this.status_cid)
    }

    Name() {
        return this.StatusProps().label;
    }

    Color() {
        return this.StatusProps().color;
    }

    IsNotRequested() {
        return this.status_cid === constants.agentStatuses.notRequested.id;
    }

    IsRequested() {
        return this.status_cid === constants.agentStatuses.requested.id;
    }

    IsConfirmed() {
        return this.status_cid === constants.agentStatuses.confirmed.id;
    }

    IsDenied() {
        return this.status_cid === constants.agentStatuses.denied.id;
    }
}

import BossModel from "@/models/BossModel";
import DossierTicket from "@/models/Dossier/Ticket/Ticket";

export default class Ticket extends BossModel {

    static apiUrl = 'proposal/ticket';

    static entity = 'proposalTickets';

    static fields() {
        return {
            id: this.number(null).nullable(),
            proposal_id: this.number(null).nullable(),
            dossier_ticket_id: this.number(null).nullable(),
            dossierTicket: this.belongsTo(DossierTicket, 'dossier_ticket_id'),
        }
    }
}

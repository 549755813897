export default class BossApiParameters {

    /**
     * Service used by BossApiService for setting url parameters and returning an url
     */
    constructor() {
        this.conditions = {};
        this.ordered = {};
        this.appended = [];
        this.withs = [];
        this.page = null;
        this.limit = 20;
        this.paginate = 0;
    }

    setPaginate(paginate = true) {
        this.paginate = paginate ? 1 : 0;
    }

    filtered(filters) {
        if (!(filters instanceof Object) || Array.isArray(filters)) {
            throw Error('BossApi.filtered value needs to be an object');
        }

        for (let key in filters) {
            if (Object.prototype.hasOwnProperty.call(filters, key)) {
                // Allow limit for now because of list components
                if (key === 'limit') {
                    this.setLimit(filters[key]);
                } else if (key === 'with') {
                    this.addWiths(filters[key]);
                } else if (key === 'withs') {
                    this.addWiths(filters[key]);
                } else {
                    this.addWhere(key, filters[key]);
                }
            }
        }
    }

    addWhere(field, value) {
        if (field === 'limit') {
            throw Error('BossApi.addWhere cant be `limit`, use limit(), default is 20');
        }
        if (field === 'paginate') {
            throw Error('BossApi.addWhere cant be `paginate`, use paginator(), default is false');
        }

        if (typeof value === "boolean"){
            value = value ? 1 : 0;
        }

        if (typeof value === "string"){
            value = value.trim();

        }

        if (value !== null && value !== '') {
            this.conditions[field] = value;
        }
    }

    addAppends(appends) {
        if (!(appends instanceof Object) || !Array.isArray(appends)) {
            throw Error('addAppends value needs to be an array');
        }

        appends.forEach(item => {
            this.appended.push(item);
        });
    }

    addOrders(orders) {
        if (!(orders instanceof Object) || Array.isArray(orders)) {
            throw Error('addOrders value needs to be an object');
        }

        for (let key in orders) {
            if (Object.prototype.hasOwnProperty.call(orders, key) && orders[key] !== null) {
                this.addOrder(key, orders[key]);
            }
        }
    }

    addOrder(field, direction = 'asc') {
        if (direction === false) {
            return;
        }

        if (direction === null) {
            direction = 'asc';
        }

        this.ordered[field] = direction;
    }

    addWiths(withs) {
        if (Array.isArray(withs)) {
            withs.forEach(item => {
                this.addWith(item);
            });
        } else if (typeof withs === 'string') {
            this.addWith(withs);
        } else {
            throw Error('addWiths value needs to be an array or a string');
        }
    }

    addWith(relation) {
        this.withs.push(relation);
    }

    setPage(page) {
        this.page = page;
    }

    setLimit(limit) {
        if (limit === null || !limit) {
            limit = 0;
        }

        this.limit = limit;
    }

    getWiths() {
        return this.withs;
    }

    getUrl(single = false) {
        let params = [];

        // conditions
        if (Object.keys(this.conditions).length > 0) {
            for (let key in this.conditions) {
                if (Array.isArray(this.conditions[key])) {
                    this.conditions[key].forEach(item => {
                        params.push(key + "[]=" + encodeURIComponent(item));
                    });
                } else {
                    params.push(key + "=" + encodeURIComponent(this.conditions[key]));
                }
            }
        }

        // ordered
        if (Object.keys(this.ordered).length > 0) {
            for (let key in this.ordered) {
                params.push('sortBy[' + key + ']=' + encodeURIComponent(this.ordered[key]));
            }
        }

        // appended
        if (this.appended.length > 0) {
            this.appended.forEach(item => {
                params.push("appends[]=" + encodeURIComponent(item));
            });
        }

        // withs
        if (this.withs.length > 0) {
            this.withs.forEach(item => {
                params.push("with[]=" + encodeURIComponent(item));
            });
        }

        if (!single) {
            if (this.page !== null) {
                params.push("page=" + encodeURIComponent(this.page));
            }

            if (this.limit !== null) {
                params.push("limit=" + encodeURIComponent(this.limit));
            }

            if (this.paginate !== null) {
                params.push("paginate=" + encodeURIComponent(this.paginate));
            }
        }

        if (params.length === 0) {
            return '';
        }
        return '?' + params.join('&');
    }
}

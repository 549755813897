<template>
    <div>
        <v-autocomplete class="mx-2 d-flex"
                        single-line
                        hide-details
                        hide-selected
                        hide-no-data
                        prepend-inner-icon="mdi-magnify"
                        label="Search by name, order id or product reference"
                        :items="items"
                        no-filter
                        item-value="key"
                        :loading="isLoading"
                        :search-input.sync="search">
            <template v-slot:item="{item}">
                <v-list-item link :href="item.link" :style="{ maxWidth: '550px' }">
                    <v-list-item-icon>
                        <v-icon :color="item.color">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title v-html="item.title"></v-list-item-title>
                        <v-list-item-subtitle v-html="item.reference"></v-list-item-subtitle>
                        <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
import Dossier from "@/models/Dossier/Dossier";
import {debounce} from "lodash";
import Agent from "@/models/Agent/Agent";

export default {
    data() {
        return {
            isLoading: false,
            search: null,
            apiResponses: [],
            items: [],
            filters: {
                term: null,
            },
            sortBy: {}
        }
    },
    watch: {
        search: {
            handler: debounce(function (term){
                this.searchTerm(term)
            },500)
        }
    },
    methods: {
        searchTerm(term){
            if (!term || term.length < 3) {
                return;
            }

            term =  term.trim().toLowerCase();

            this.items = [];

            this.isLoading = true;
            this.filters.term = term

            return Dossier.api()
                .action('search-by-term-dossiers')
                .filtered(this.filters)
                .ordered(this.sortBy)
                .get()
                .then((response) => {
                    if(response.length === 0){
                        this.items.push({
                            key: 'no_results',
                            title: 'No results found',
                            subtitle: 'Try another search term',
                            icon: 'mdi-alert-circle',
                            color: 'error',
                            link: null,
                        });
                    }
                    response.forEach(item => {
                        this.items.push({
                            key: 'dossier_' + item.id,
                            reference: item.reference ? 'Product reference: ' + item.reference : null,
                            title: 'Order id: ' + item.id,
                            subtitle: item.firstname + ' ' + item.lastname + ' | departure: ' + item.trip_starts_at,
                            icon: 'mdi-view-list',
                            color: 'primary',
                            link: '/agent/' + Agent.loggedIn().public_id + '/dossier/' + item.public_id,
                        });
                    });

                    this.loading = false;
                });
        },
    },
}
</script>

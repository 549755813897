<template>
    <v-menu offset-y min-width="200">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
                <v-icon left>mdi-face-agent</v-icon>
                <div class="d-none d-md-flex">{{ agent.name }}</div>
                <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-subheader>{{ agent.name }}</v-subheader>
            <v-list-item class="d-flex d-md-none" to="/" link>
                <v-list-item-action>
                    <v-icon>mdi-receipt-clock-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Pending Orders
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="d-flex d-md-none" to="/all-orders" link>
                <v-list-item-action>
                    <v-icon>mdi-folder-arrow-left-right-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        All Orders
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/login?logout=1" link>
                <v-list-item-action>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import Agent from "@/models/Agent/Agent";

export default {
    props: {
        agent: {
            type: Agent,
            required: true
        }
    },
}
</script>

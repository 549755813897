import BossModel from "@/models/BossModel";
import Agent from '@/models/Agent/Agent';
import Dossier from "@/models/Dossier/Dossier";
import FileModel from "@/models/File/File";

export default class Version extends BossModel {

    static apiUrl = 'agent/attachment/version';

    static entity = 'agentAttachmentVersion';

    static fields() {
        return {
            id: this.number(null).nullable(),
            file_id: this.number(null).nullable(),
            comment: this.attr(null).nullable(),
            dossier_id: this.number(null).nullable(),
            agent_id: this.number(null).nullable(),
            created_at: this.attr(null),
            agent: this.belongsTo(Agent, 'agent_id'),
            dossier: this.belongsTo(Dossier, 'dossier_id'),
            file: this.belongsTo(FileModel, 'file_id'),
        }
    }
}

import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'

Vue.use(Vuex)

// Create a new instance of Database.
const database = new VuexORM.Database();

const requireComponent = require.context(
    '@/models', // The relative path of the components folder
    true, // Scan includes subfolders
    /[A-Z]\w+\.(js)$/ // The regular expression used to match base component filenames
)

// Register Models to Database.
requireComponent.keys().forEach(fileName => {
    const model = requireComponent(fileName)

    if (model.default.entity) {
        database.register(model.default)
    }
})

// Create Vuex Store and register database through Vuex ORM.
const store = new Vuex.Store({
    plugins: [VuexORM.install(database)],
})

export default store

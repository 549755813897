import AbstractApiService from "@/utils/Model/ApiService/AbstractApiService";
import Axios from 'axios';

export default class InstanceModelApiService extends AbstractApiService{

    constructor(model) {
        super();

        this._model = model;
        this._staticModel = model.constructor;
    }

    /**
     * update in api by current model instance
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async update(payload = this._model, useStore = true) {
        let url = this._staticModel.apiUrl + '/' + this._model.id;
        payload = Object.assign({_method: 'put'}, payload);
        return Axios.post(url, this.constructor.convertToFormData(payload)).then(response => {
            return this.returnSingle(response, useStore);
        }).catch(error => {
            return this._catch(error);
        });
    }

    /**
     * @param payload
     * @param useStore
     * @returns {Promise<AxiosResponse<T>>}
     */
    async delete(payload = null, useStore = true) {
        let url = this._staticModel.apiUrl + '/' + this._model.id;

        return Axios.delete(url, {data: payload}).then(response => {
            if (useStore) {
                this._model.$delete();
            }
            return response.data;
        }).catch(error => {
            return this._catch(error);
        });
    }
}

import {Model} from "@vuex-orm/core";

export default class AgentProductDetailDossierTraveller extends Model {

    static entity = 'agentProductDetailDossierTraveller';

    static primaryKey = ['agent_product_detail_id', 'dossier_traveller_id'];

    static fields () {
        return {
            agent_product_detail_id: this.number(null),
            dossier_traveller_id: this.number(null),
        }
    }
}

import BossModel from "@/models/BossModel";
import Currency from "@/models/Currency/Currency";

export default class Code extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'agent/code';

    /**
     *
     * @type {string}
     */
    static entity = 'agentCodes';

    static fields() {
		return {
			id: this.number(null).nullable(),
			agent_id: this.number(null).nullable(),
			name: this.attr(null),
            default_currency_id: this.number(null).nullable(),
            defaultCurrency: this.belongsTo(Currency, 'default_currency_id'),
		}
	}
}

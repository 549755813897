import BossModel from "@/models/BossModel";
import Currency from "@/models/Currency/Currency";

export default class Price extends BossModel {

    static apiUrl = 'agent/product/price';

    static entity = 'agentProductPrices';

    static fields() {
        return {
            id: this.number(null).nullable(),
            agent_product_id: this.number(null).nullable(),
            currency_id: this.number(null).nullable(),
            local_price: this.number(null).nullable(),
            comment: this.attr(null).nullable(),
            created_at: this.attr(null),
            currency: this.belongsTo(Currency, 'currency_id'),
        }
    }
}

<template>
            <v-row align="center" no-gutters class="mx-0 px-0">
                <v-col>
                    <v-breadcrumbs class="mx-0 px-0" v-if="breadcrumbs" :items="breadcrumbs">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" :exact="item.exact">
                                {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>
</template>

<script>
export default {
    name: "BreadCrumbBanner",
    components: {},
    props: {
        breadcrumbs: {
            required: false,
            type: Array,
            default: null,
        }
    },
}
</script>


import BossModel from "@/models/BossModel";

export default class Auth extends BossModel {

    static entity = 'agentAuths';

    static fields() {
        return {
            agent_id: this.number(null).nullable(),
        }
    }

    static setAgentId(agentId) {
        this.query().deleteAll();

        return this.create({data: {agent_id: agentId}});
    }

    static getAgentId() {
        const item = this.query().first();

        return item?.agent_id;
    }

    static removeAgentId() {
        this.query().deleteAll();
    }
}

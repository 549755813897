import BossModel from "@/models/BossModel";
import constants from "@/constants";

export default class Identity extends BossModel {

    static apiUrl = 'dossier/passport/identity';

    static entity = 'dossierPassportIdentities';

    static fields() {
        return {
            id: this.number(null).nullable(),
            dossier_traveller_id: this.number(null),
            given_names: this.attr(null),
            last_name: this.attr(null),
            date_of_birth: this.attr(null),
            gender_cid: this.attr(null),
            document_number: this.attr(null),
            date_of_expiry: this.attr(null),
            nationality: this.attr(null),
            created_at: this.attr(null),
        }
    }

    Gender() {
        let gender = constants.genders.find(item => item.id == this.gender_cid);
        if (gender === undefined) {
            return 'unknown';
        }

        return gender.label;
    }
}

<template>
    <v-app-bar app dark color="primary">
        <img src="https://www.333travel.nl/img/logo-white.png" height="40" class="mr-4" alt="333travel"/>
        <v-toolbar-title>
            <span class="text-overline ml-4 d-none d-md-flex">
                Agent portal
            </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div class="flex">
            <QuickSearch/>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="d-none d-md-flex mr-2" :loading="loadingItemsCount" text to="/">
            <v-icon left>mdi-receipt-clock-outline</v-icon>
            Pending Orders ({{ itemsCount }})
        </v-btn>
        <v-btn class="d-none d-md-flex mr-2" text to="/all-orders">
            <v-icon left>mdi-folder-arrow-left-right-outline</v-icon>
            All Orders
        </v-btn>
        <AccountMenu :agent="agent"/>
    </v-app-bar>
</template>

<script>
import {defineComponent} from "vue";
import AccountMenu from "@/components/Layout/Partials/AccountMenu.vue";
import QuickSearch from "@/components/QuickSearch/index.vue";
import Dossier from "@/models/Dossier/Dossier";
import Agent from "@/models/Agent/Agent";

export default defineComponent({
    components: {QuickSearch, AccountMenu},
    data() {
        return {
            itemsCount: 0,
            loadingItemsCount: true,
        }
    },
    props: {
        agent: {
            type: Agent,
            required: true,
        },
    },
    methods: {
        getPendingDossiersCount() {
            return Dossier.api()
                .get()
                .then((response) => {
                    this.itemsCount = response.length;
                });
        },
    },
    created() {
        this.getPendingDossiersCount().then(() => {
            this.loadingItemsCount = false;
        });
    }
})
</script>

<template>
    <v-app>
        <TopBarAgent v-if="agent" :agent="agent"/>
        <TopBarPublic v-else/>
        <router-view/>
        <notifications/>
    </v-app>
</template>

<script>
import TopBarPublic from "@/components/Layout/Partials/TopBarPublic";
import TopBarAgent from "@/components/Layout/Partials/TopBarAgent";
import Agent from "@/models/Agent/Agent";
import LocalStorageService from "@/utils/Services/LocalStorageService";
import BreadCrumbBanner from "@/components/Layout/Partials/BreadCrumbBanner";
import Notifications from "@/components/General/Notifications";

export default {
    name: 'App',
    components: {Notifications, BreadCrumbBanner, TopBarPublic, TopBarAgent},
    methods: {
        async auth() {
            if (LocalStorageService.get('token')) {
                await Agent.fetchLoggedIn();
            }
        }
    },
    computed: {
        agent() {
            return Agent.loggedIn();
        },
    },
    beforeMount() {
        this.auth();
    }
};
</script>

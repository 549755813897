<template>
    <v-app-bar app dark color="primary">
        <img src="https://www.333travel.nl/img/logo-white.png" height="40" class="mr-4" alt="333travel"/>
        <v-toolbar-title>
            <span class="text-overline ml-4 d-none d-md-flex">
                Agent portal
            </span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn text v-if="!agent" to="/login">
            <v-icon left>mdi-login-variant</v-icon>
            Login
        </v-btn>
    </v-app-bar>
</template>

<script>
import AccountMenu from "@/components/Layout/Partials/AccountMenu";
import Agent from "@/models/Agent/Agent";
import QuickSearch from "@/components/QuickSearch";

export default {
    components: {
        AccountMenu,
        QuickSearch,
    },
    computed: {
        agent() {
            return Agent.loggedIn();
        },
    },
}
</script>

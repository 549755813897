import BossModel from "@/models/BossModel";

export default class Customer extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'customer';

    /**
     *
     * @type {string}
     */
    static entity = 'customers';

    static fields() {
        return {
            id: this.number(null).nullable(),
            firstname: this.attr(null),
            lastname: this.attr(null),
            email: this.attr(null),
            telephone: this.attr(null),
        }
    }
}

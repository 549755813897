import BossModel from "@/models/BossModel";

export default class ProposalNonClosingDay extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'proposal/non-closing-day';

    /**
     *
     * @type {string}
     */
    static entity = 'proposalNonClosingDays';

    static fields() {
        return {
            id: this.number(null).nullable(),
            proposal_id: this.number(null).nullable(),
            start_date: this.attr(null),
            end_date: this.attr(null),
            start_location: this.attr(null),
            end_location: this.attr(null),
            name: this.attr(null),
            message: this.attr(null),
        }
    }
}

import BossModel from "@/models/BossModel";
import File from "@/models/File/File";
import Message from "@/models/Agent/Message/Message";

export default class Attachment extends BossModel {

    static apiUrl = 'agent/message/attachment';

    static entity = 'agentMessageAttachment';

    static fields() {
        return {
            id: this.number(null),
            file_id: this.number(null),
            agent_message_id: this.number(null),
            message: this.belongsTo(Message, 'agent_message_id'),
            file: this.belongsTo(File, 'file_id'),
        }
    }
}

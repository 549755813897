import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/scss/main.scss'
import '@/plugins/notifications';

import('@/plugins/bugsnag')
import('@/plugins/vuelidate')

import('@/utils/filters')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

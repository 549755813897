import BossModel from "@/models/BossModel";
import Traveller from "@/models/Dossier/Traveller/Traveller";
import AgentProductDetailDossierTraveller from "@/models/Agent/Product/Detail/AgentProductDetailDossierTraveller";

export default class Detail extends BossModel {

    static apiUrl = 'agent/product/detail';

    static entity = 'agentProductDetails';

    static fields() {
        return {
            id: this.number(null).nullable(),
            agent_product_id: this.number(null).nullable(),
            name: this.attr(null),
            travellers: this.belongsToMany(Traveller, AgentProductDetailDossierTraveller, 'agent_product_detail_id', 'dossier_traveller_id'),
        }
    }
}

export default {
    agentStatuses: {
        notRequested: {id: 0, label: 'Open', color: 'grey lighten-2'},
        requested: {id: 1, label: 'Requested', color: 'warning'},
        confirmed: {id: 2, label: 'Confirmed', color: 'success'},
        changeRequired: {id: 4, label: 'Denied', color: 'error'},
        denied: {id: 8, label: 'Denied', color: 'error'}
    },
    phoneNumber: '0031348441951',
    genders: [
        {id: 0, label: ''},
        {id: 1, label: 'Male'},
        {id: 2, label: 'Female'}
    ],
    pronouns: [
        {id: 0, label: ''},
        {id: 1, label: 'Mr.'},
        {id: 2, label: 'Mrs.'}
    ],
}

import {Model} from "@vuex-orm/core";

export default class DossierTicketDossierTicketFlight extends Model {

    static entity = 'dossierTicketDossierTicketFlight';

    static primaryKey = ['dossier_ticket_id', 'dossier_ticket_flight_id'];

    static fields () {
        return {
            dossier_ticket_id: this.number(null),
            dossier_ticket_flight_id: this.number(null),
        }
    }
}

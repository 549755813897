import BossModel from "@/models/BossModel";
import Version from "@/models/Customer/Traveller/Version/Version";
import Identity from "@/models/Dossier/Passport/Identity/Identity";

export default class Traveller extends BossModel {

    static apiUrl = 'dossier/traveller';

    static entity = 'travellers';

    static fields() {
        return {
            id: this.number(null).nullable(),
            dossier_id: this.number(null).nullable(),
            customer_traveller_version_id: this.number(null).nullable(),
            version: this.belongsTo(Version, 'customer_traveller_version_id'),
            identity: this.hasOne(Identity, 'dossier_traveller_id'),
        }
    }
}

import VueRouter from "vue-router";
import {difference, isArray, isEmpty, isEqual, isNil, isString, omitBy, pick} from "lodash";

export default class UrlParameterService {

    constructor(router = {}, defaultSortBy = {}, defaultFilters = {}) {
        if (!(router instanceof VueRouter)) {
            throw 'Router is not an instance of VueRouter';
        }
        this.savedParameters = {};
        this.router = router;
        this.defaultFilters = defaultFilters;
        this.defaultSortBy = defaultSortBy;

        this.parameters = {
            page: 1,
            filters: {},
            sortBy: {},
        };

        if (isEmpty(this.router.currentRoute.query)) {
            this.parameters = {
                page: 1,
                filters: Object.assign({}, this.defaultFilters),
                sortBy: Object.assign({}, omitBy(this.defaultSortBy, isNil)),
            };
        } else {
            this.setParametersByUrl();
        }
    }

    setFilters(filters = {}) {
        this.parameters.filters = this.constructor.protectFields(filters, Object.keys(this.defaultFilters));
    }

    getFilters() {
        return Object.assign({}, this.parameters.filters);
    }

    setPage(page = null) {
        this.parameters.page = page;
    }

    getPage() {
        return this.parameters.page;
    }

    setSortBy(sortBy = {}) {
        this.parameters.sortBy = this.constructor.protectFields(sortBy, Object.keys(this.defaultSortBy));
    }

    setSortByDataTable(columns = [], desc = []) {
        let sortBy = {};
        columns.forEach((column, index)=> {
            sortBy[column] = desc[index] ? 'desc' : 'asc';
        });
        this.setSortBy(sortBy);
    }

    getSortBy() {
        return Object.assign({}, this.parameters.sortBy);
    }

    getDataTableOptions() {
        let dataTableOptions = {
            sortBy: [],
            sortDesc: [],
        };

        if (this.getSortBy()) {
            dataTableOptions.sortBy = Object.keys(this.getSortBy());
            dataTableOptions.sortDesc = Object.values(this.getSortBy()).map(direction => {
                return direction === 'desc';
            });
        }

        if (this.getPage()) {
            dataTableOptions.page = this.getPage();
        }

        return dataTableOptions;
    }

    setParametersByUrl() {
        let parameters = this.constructor.formatQueryParameters(this.router.currentRoute.query);

        this.setParameters(parameters);
    }

    saveParameters() {
        this.savedParameters = this.getParameters();
    }

    areParametersNew() {
        return !isEqual(this.savedParameters, this.getParameters());
    }

    setParameters(parameters = {}) {
        if (parameters.sortBy) {
            this.setSortBy(parameters.sortBy ? parameters.sortBy : {});
            delete parameters.sortBy;
        }

        if (parameters.page) {
            this.setPage(parameters.page ? parameters.page : 1);
            delete parameters.page;
        }

        this.setFilters(parameters);
    }

    getParameters() {
        let parameters = this.getFilters();

        if (this.getSortBy()) {
            parameters.sortBy = this.getSortBy();
        }

        if (this.getPage()) {
            parameters.page = this.getPage();
        }

        return parameters;
    }

    reset() {
        this.setFilters(this.defaultFilters);
        this.setSortBy(this.defaultSortBy);
        this.setPage(1);
    }

    pushUrl() {
        if (!isEqual(this.constructor.formatQueryParameters(this.router.currentRoute.query), this.getParameters())) {
            this.router.replace({
                query: this.getParameters(),
            });
        }
    }

    static protectFields(data = {}, allowedFields = []) {
        if (data.length) {
            return {};
        }

        const tryingFields = Object.keys(data);
        const failedFields = difference(tryingFields, allowedFields);

        if (failedFields.length > 0) {
            console.warn('Trying to filter on fields that dont exist in the defaults: ' + failedFields.join(', '));
        }

        return omitBy(pick(data, allowedFields), isNil);
    }

    static formatQueryParameters(parameters = {}) {
        for (let key in parameters) {
            if (parameters[key] === '') {
                parameters[key] = null;
            }
            else if (parameters[key] === 'false') {
                parameters[key] = false;
            }
            else if (parameters[key] === 'true') {
                parameters[key] = true;
            }
            else if (isString(parameters[key]) && parameters[key] == parseInt(parameters[key])) {
                parameters[key] = parseInt(parameters[key]);
            }
            else if (isArray(parameters[key])) {
                for (let key2 in parameters[key]) {
                    if (isString(parameters[key][key2]) && parameters[key][key2] == parseInt(parameters[key][key2])) {
                        parameters[key][key2] = parseInt(parameters[key][key2]);
                    }
                }
            }
        }

        return parameters;
    }
}

import BossModel from "@/models/BossModel";
import Agent from '@/models/Agent/Agent';
import Dossier from "@/models/Dossier/Dossier";
import User from "@/models/User/User";

export default class Message extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'agent/message';

    /**
     *
     * @type {string}
     */
    static entity = 'agentMessages';

    static fields() {
        return {
            id: this.number(null).nullable(),
            message: this.attr(null).nullable(),
            user_id: this.number(null).nullable(),
            dossier_id: this.number(null).nullable(),
            agent_id: this.number(null).nullable(),
            created_at: this.attr(null),
            user: this.belongsTo(User, 'user_id'),
            agent: this.belongsTo(Agent, 'agent_id'),
            dossier: this.belongsTo(Dossier, 'dossier_id')
        }
	}

    static createMessage(agentHash, dossierHash, message) {
        return this.api().action(agentHash + '/' + dossierHash).create({
            'message': message,
        });
    }
}

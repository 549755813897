import BossModel from "@/models/BossModel";
import Agent from '@/models/Agent/Agent';
import Currency from '@/models/Currency/Currency';
import User from "@/models/User/User";

export default class AgentCurrency extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'agent/currency';

    /**
     *
     * @type {string}
     */
    static entity = 'agentCurrencies';

    static fields() {
		return {
			id: this.number(null).nullable(),
			agent_id: this.number(null).nullable(),
			currency_id: this.number(null).nullable(),
            currency: this.belongsTo(Currency, 'currency_id'),
            user: this.belongsTo(User, 'user_id'),
            agent: this.belongsTo(Agent, 'agent_id'),
		}
	}

    /**
     *
     * @returns {boolean|*}
     */
    User() {
        return this.getRelated('user');
    }

    /**
     *
     * @returns {boolean|*}
     */
    Agent() {
        return this.getRelated('agent');
    }

    /**
     *
     * @returns {boolean|*}
     */
    Currency() {
        return this.getRelated('currency');
    }

}

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            warning: 'mdi-alert',
            error: 'mdi-alert-octagon',
        }
    },
    theme: {
        default: 'light',
        light: true,
        dark: false,
        options: {
            // DOC: https://vuetifyjs.com/en/customization/theme/#custom-properties
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#00546d',
                secondary: '#08b0ba',
                accent: '#FF5722',
                error: '#c91e0d',
                info: '#006383',
                success: '#9bc90d',
                warning: '#efc11a',
            },
        },
    },
});

import BossModel from "@/models/BossModel";
import Dossier from "@/models/Dossier/Dossier";
import Traveller from "@/models/Dossier/Traveller/Traveller";
import Flight from "@/models/Dossier/Ticket/Flight/Flight";
import DossierTicketDossierTicketFlight from "@/models/Dossier/Ticket/DossierTicketDossierTicketFlight";
import ProposalTicket from  "@/models/Proposal/Ticket/Ticket";

export default class Ticket extends BossModel {

    static apiUrl = 'dossier/ticket';

    static entity = 'dossierTickets';

    static fields() {
        return {
            id: this.number(null).nullable(),
            dossier_id: this.number(null).nullable(),
            dossier_traveller_id: this.number(null).nullable(),
            traveller_name: this.attr(null),
            start_date: this.attr(null),
            end_date: this.attr(null),
            dossier: this.belongsTo(Dossier, 'dossier_id'),
            traveller: this.belongsTo(Traveller, 'dossier_traveller_id'),
            flights: this.belongsToMany(Flight, DossierTicketDossierTicketFlight, 'dossier_ticket_id', 'dossier_ticket_flight_id'),
            proposalTickets: this.hasMany(ProposalTicket, 'dossier_ticket_id'),
        }
    }

}
